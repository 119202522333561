$emission: #6BD69E
$casing: #F89E55
$electric: #F46746
$tank: #876FEF
$vessel: #57D4E5
$pipeline: #3699F1

@font-face
  font-family: SofiaProRegular
  src: url("/fonts/sofia-pro-regular.otf") format("opentype")

@font-face
  font-family: SofiaProSemiBold
  src: url("/fonts/sofia-pro-semi-bold.otf") format("opentype")

@font-face
  font-family: SofiaProLight
  src: url("/fonts/sofia-pro-light.otf") format("opentype")

@font-face
  font-family: SofiaProUltraLight
  src: url("/fonts/sofia-pro-ultralight.otf") format("opentype")

@font-face
  font-family: SofiaProMedium
  src: url("/fonts/sofia-pro-medium.otf") format("opentype")

.sofia-pro-light
  font-family: SofiaProLight

.sofia-pro-ultra-light
  font-family: SofiaProUltraLight

.sofia-pro-regular
  font-family: SofiaProRegular

.sofia-pro-medium
  font-family: SofiaProMedium

.sofia-pro-semi-bold
  font-family: SofiaProSemiBold

body
  background-color: #F5F5F5
  font-family: SofiaProLight

  h1
    font-family: SofiaProSemiBold
    font-size: 1.75rem
    color: #222427

  h2
    font-family: SofiaProLight
    color: #222427

  .pa-1-5
    padding-left: 1.5rem
    padding-right: 1.5rem

  .full-bleed-background
    background-size: 100% 100% !important
    background: url('/images/gradient.png') no-repeat center bottom
    min-height: 163px

    &.emission
      background-image: url('/images/hero-gradient-emission.png')

    &.casing
      background-image: url('/images/hero-gradient-casing.png')

    &.electric
      background-image: url('/images/hero-gradient-electric.png')

    &.tank
      background-image: url('/images/hero-gradient-tank.png')

    &.pipeline
      background-image: url('/images/hero-gradient-pipeline.png')

    &.vessel
      background-image: url('/images/hero-gradient-vessel.png')

    &.clients
      background-image: url('/images/lines-bg.png')

  .full-bleed-background-lines
    background-size: contain !important
    background: url('/images/lines-bg.png') no-repeat center 65%
    min-height: 163px
    background-color: white

  .full-bleed-background-clients
    background-size: 100% 100% !important
    background: url('/images/lines-bg.png') no-repeat center bottom
    min-height: 163px

  .badge-hiring
    font-size: 0.6rem
    padding: 0.25rem 0.25rem 0.1rem
    border-color: #C8C9CA
    position: relative
    top: -0.2rem
    display: inline-block

  .badge-careers
    padding-left: .7rem
    padding-right: .7rem


  div.divider
    hr
      height: 0
      // overflow: visible
      border: none
      border-top: thin #e1e1e1 solid
      // margin-top: 100px
      // margin-bottom: 100px

  .sub-heading
    /* When using the CNTRA: */
    font-family: SofiaProLight
    color: #686D75
    line-height: 26px

  .security
    background: url('/images/security-background.png') no-repeat center bottom
    background-size: 100% 100%

  .platform-title
    font-family: SofiaProLight
    font-size: 13px
    color: #686D75
    letter-spacing: 1.85px
    text-transform: uppercase

  .bottom-callout-background
    background: url('/images/bottom-callout-background-home.png') no-repeat center bottom
    background-size: 100% 100%

    &.emission
      background-image: url('/images/bottom-callout-background-emission.png')

    &.electric
      background-image: url('/images/bottom-callout-background-electric.png')

    &.casing
      background-image: url('/images/bottom-callout-background-casing.png')

    &.tank
      background-image: url('/images/bottom-callout-background-tank.png')

    &.pipeline
      background-image: url('/images/bottom-callout-background-pipeline.png')

    &.vessel
      background-image: url('/images/bottom-callout-background-vessel.png')

  .bg-emission
    background-color: $emission

  div.client-logos-container-container
    position: relative

    &.clients
      opacity: 0.5
      filter: grayscale(100%)

      div.gradient-top
        top: 0
        background: -moz-linear-gradient(top, rgba(245,245,245,1) 0%, rgba(245,245,245,0) 100%)
        background: -webkit-linear-gradient(top, rgba(245,245,245,1) 0%,rgba(245,245,245,0) 100%)
        background: linear-gradient(to bottom, rgba(245,245,245,1) 0%,rgba(245,245,245,0) 100%)
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#00f5f5f5',GradientType=0 ) /* IE6-9 */

    div.gradient-top,
    div.gradient-bottom
      position: absolute
      left: 0
      right: 0
      height: 30px

    div.gradient-top
      top: 0
      background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
      background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)
      background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ) /* IE6-9 */

    div.gradient-bottom
      bottom: 0
      background: -moz-linear-gradient(bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
      background: -webkit-linear-gradient(bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)
      background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%)
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ) /* IE6-9 */

    div.client-logos
      img
        max-height: 80px

  div.solution

    a
      border: 1px solid #EEEEEE
      border-top: 4px solid
      // min-width: 120px

      &:hover
        box-shadow: 0px 12px 19px 0px rgba(171,170,169,0.30)

        div.button
          transition: background-color 200ms linear

      &.emission
        border-top-color: $emission

        &:hover
          div.button
            background-color: $emission

      &.casing
        border-top-color: $casing

        &:hover
          div.button
            background-color: $casing

      &.electric
        border-top-color: $electric

        &:hover
          div.button
            background-color: $electric

      &.tank
        border-top-color: $tank

        &:hover
          div.button
            background-color: $tank

      &.vessel
        border-top-color: $vessel

        &:hover
          div.button
            background-color: $vessel

      &.pipeline
        border-top-color: $pipeline

        &:hover
          div.button
            background-color: $pipeline

      &.next-solution
        border-top: 1px solid
        border-color: #d5d5d5

        img
          height: 55px
          width: 200px
          margin-bottom: 1.4rem

      div.text
        font-family: SofiaProLight
        font-size: 16px
        color: #686D75
        letter-spacing: 0px
        line-height: 26px
        text-decoration: none

nav
  color: #595E66
  font-family: SofiaProRegular

  .mainNav
    a
      font-family: SofiaProMedium
      font-size: 1rem
      color: #5A5E65

      &:hover,
      &.active,
      &.primary
        color: #222427

  div.shadow
    box-shadow: 0px 12px 19px 0px rgba(171,170,169,0.30)

    @media screen and (min-width: 53em)
      box-shadow: none

  section#solutionsNav
    box-shadow: 0px 12px 19px 0px rgba(171,170,169,0.30)
    // transition: visibility 0s, opacity 0.25s linear

    &:after
      bottom: 100%
      left: 35%
      border: solid transparent
      content: " "
      height: 0
      width: 0
      position: absolute
      pointer-events: none
      border-color: rgba(255, 255, 255, 0)
      border-bottom-color: #ffffff
      border-width: 20px
      margin-left: -20px

  section
    h1
      font-family: SofiaProLight
      color: #92969D

    div.solution
      a
        border: 1px solid #EEEEEE
        border-top: 3px solid
        // min-width: 120px

        &.emission
          border-top-color: $emission

        &.casing
          border-top-color: $casing

        &.electric
          border-top-color: $electric

        &.tank
          border-top-color: $tank

        &.vessel
          border-top-color: $vessel

        &.pipeline
          border-top-color: $pipeline

    .button-get-a-demo
      letter-spacing: 1px
      font-family: SofiaProSemiBold
      box-shadow: 0px 2px 6px 0px rgba(58,69,80,0.30)

    hr
      border: none
      border-top: thin solid #E8E8E8

    ul
      a
        font-family: SofiaProLight
        color: #92969D

        &:hover
          color: #797D82

footer
  a
    color: #91969D

  .links-title
    font-family: SofiaProSemiBold
    font-size: 13px
    color: #020202
    letter-spacing: 1px
    text-transform: uppercase

.hover-emission
  &:hover, &:focus
    color: $emission

.hover-casing
  &:hover, &:focus
    color: $casing

.hover-electric
  &:hover, &:focus
    color: $electric

.hover-tank
  &:hover, &:focus
    color: $tank

.hover-vessel
  &:hover, &:focus
    color: $vessel

.hover-pipeline
  &:hover, &:focus
    color: $pipeline

input
  border: none

  &[type="text"],
  &[type="email"],
  &[type="tel"]
    border: 5px solid white
    padding: 15px

textarea
  border: 5px solid white
  padding: 15px

.request-a-solution
  li
    transition: box-shadow 0.25s
    transition: opacity 0.25s

    &.checked
      box-shadow: 0px 12px 19px 0px rgba(171,170,169,0.30)
      opacity: 1.0

